<template>
  <validation-observer
    #default="{ handleSubmit }"
    ref="generalInfoRules"
  >
    <b-form
      class="mt-1"
      @submit.prevent="handleSubmit(addEvaluationRequest)"
      @reset.prevent="resetForm"
    >
      <b-row>
        <b-col
          cols="12"
          md="12"
        >
          <b-alert
            v-model="showMessage"
            dismissible
            :variant="messageType"
          >
            <div class="alert-body">
              <feather-icon
                class="mr-25"
                icon="InfoIcon"
              />
              <span
                class="ml-25"
                v-html="message"
              />
            </div>
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: client -->
          <validation-provider
            #default="validationContext"
            vid="client"
            name="client"
            rules="required"
          >
            <b-form-group
              label="العميل *"
              label-for="clientـid"
              class="text-bold"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="evaluationRequestData.client_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="filteredClients"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="client"
                @input="clientChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: quotation_id -->
          <validation-provider
            #default="validationContext"
            vid="quotation_id"
            name="quotation_id"
            rules="required"
          >
            <b-form-group
              label="عرض السعر *"
              label-for="quotation_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="evaluationRequestData.quotation_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="quotations"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="quotation_id"
                @input="quotation_idChanged"
                @loading="isLoading"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: contract_id -->
          <validation-provider
            #default="validationContext"
            vid="contract_id"
            name="contract_id"
            rules="required"
          >
            <b-form-group
              label="عقد العميل *"
              label-for="contract_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="evaluationRequestData.contract_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="contracts"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="contract_id"
                @input="contract_idChanged"
                @loading="isLoading"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: city -->
          <validation-provider
            v-if="cities.length > 0"
            #default="validationContext"
            vid="city_id"
            name="city_id"
            rules="required"
          >
            <b-form-group
              label="المدينة *"
              label-for="city_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="evaluationRequestData.property.city_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="cities"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="city_id"
                @input="cityChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: district -->
          <validation-provider
            #default="validationContext"
            vid="district_id"
            name="district_id"
            rules="required"
          >
            <b-form-group
              label="الحي *"
              label-for="district_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="evaluationRequestData.property.district_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="districts"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="district_id"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: part_number -->
          <validation-provider
            #default="validationContext"
            vid="part_number"
            name="part_number"
            rules="required"
          >
            <b-form-group
              label="رقم البلوك *"
              label-for="part_number"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="part_number"
                v-model="evaluationRequestData.property.part_number"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: sketch_number -->
          <validation-provider
            #default="validationContext"
            vid="sketch_number"
            name="sketch_number"
            rules="required"
          >
            <b-form-group
              label="رقم المخطط *"
              label-for="sketch_number"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="sketch_number"
                v-model="evaluationRequestData.property.sketch_number"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: street -->
          <validation-provider
            #default="validationContext"
            vid="street"
            name="street"
          >
            <b-form-group
              label="اسم الشارع"
              label-for="street"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="street"
                v-model="evaluationRequestData.property.street"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: piece_number -->
          <validation-provider
            #default="validationContext"
            vid="piece_number"
            name="piece_number"
          >
            <b-form-group
              label="رقم القطعة"
              label-for="piece_number"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="piece_number"
                v-model="evaluationRequestData.property.piece_number"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: property_classification_id -->
          <validation-provider
            #default="validationContext"
            vid="property_classification_id"
            name="property_classification_id"
            rules="required"
          >
            <b-form-group
              label="تصنيف العقار *"
              label-for="property_classification_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="
                  evaluationRequestData.property.property_classification_id
                "
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="classifications"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="property_classification_id"
                @input="propertyClassificationChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: property_type_id -->
          <validation-provider
            #default="validationContext"
            vid="property_type_id"
            name="property_type_id"
            rules="required"
          >
            <b-form-group
              label="نوع العقار *"
              label-for="property_type_id"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="evaluationRequestData.property.property_type_id"
                :dir="$store.state.appConfig.layout.isRTL ? 'rtl' : 'ltr'"
                :options="filteredTypes"
                :reduce="(val) => val.value"
                :clearable="false"
                input-id="property_type_id"
                @input="propertyTypeChanged"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="TypeCondition"
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: property_type_id -->
          <validation-provider
            #default="validationContext"
            vid="property_floor_number"
            name="property_floor_number"
          >
            <b-form-group
              label="رقم الدور"
              label-for="property_floor_number"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="property_floor_number"
                v-model="evaluationRequestData.property.property_floor_number"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col
          v-if="TypeCondition"
          cols="12"
          md="6"
          lg="6"
        >
          <!-- Field: property_type_id -->
          <validation-provider
            #default="validationContext"
            vid="property_apartment_number"
            name="property_apartment_number"
            rules="required"
          >
            <b-form-group
              label="رقم الشقة"
              label-for="property_apartment_number"
              :state="getValidationState(validationContext)"
            >
              <b-form-input
                id="property_apartment_number"
                v-model="evaluationRequestData.property.property_apartment_number"
              />
              <b-form-invalid-feedback
                :state="getValidationState(validationContext)"
              >
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import {
  BButton,
  BMedia,
  BAlert,
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormInput,
  BFormInvalidFeedback,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BFormTextarea,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onUnmounted } from '@vue/composition-api';
import { required, alphaNum, validEmail } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import store from '@/store';
import router from '@/router';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { alertMessageMixin } from '@core/mixins/ui/feedback';
import evaluationRequestStore from '../../evaluationRequestStore';

export default {
  components: {
    BButton,
    BMedia,
    BAlert,
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    BFormInvalidFeedback,
    BFormTextarea,
    vSelect,
    flatPickr,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  mixins: [alertMessageMixin],
  props: {
    contract: {
      type: Object,
      required: false,
    },
    cost: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      quotations: [],
      districts: [],
      isLoading: false,
      TypeCondition: false,
      required,
      alphaNum,
      validEmail,
      config: {
        locale: Arabic,
      },
    };
  },
  mounted() {
    this.$root.$on('validateGeneralInfo', async (data) => {
      this.$refs.generalInfoRules.validate().then((success) => {
        this.$root.$emit('generalInfoValidated', { success, data: this.evaluationRequestData });
      });
    });
  },
  beforeDestroy() {
    this.$root.$off('validateGeneralInfo');
  },
  methods: {
    propertyClassificationChanged(id) {
      this.evaluationRequestData.property.property_type_id = null;
      this.filteredTypes = this.types.filter(
        (type) => type.property_classification_id === id,
      );
    },
    propertyTypeChanged(id) {
      this.evaluationRequestData.property.property_floor_number = null;
      this.evaluationRequestData.property.property_apartment_number = null;
      // This method will be called when the selection changes
      const selectedValueObject = this.filteredTypes.find((item) => item.value === id);

      if (selectedValueObject) {
        const selectedValue = selectedValueObject.label; // Change 'label' to the property that holds the value
        if (selectedValue === 'عمارة سكنية' || selectedValue === 'شقة') {
          this.TypeCondition = true;
        } else {
          this.TypeCondition = false;
        }
      }
    },
    fetchDistricts(city_id) {
      this.isLoading = true;
      console.log('fetchDistricts', city_id);
      this.$store
        .dispatch('evaluationRequest/fetchDistricts', { id: city_id })
        .then((response) => {
          const { data } = response.data;
          this.districts = data.map((district) => ({
            label: district.name_ar,
            value: district.id,
          }));
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          console.log(err);
        });
    },
    cityChanged(city_id) {
      this.fetchDistricts(city_id);
      this.evaluationRequestData.property.city_id = city_id;
      this.evaluationRequestData.property.district_id = null;
    },
    typeChanged(type) {
      this.evaluationRequestData.type = type;
      this.evaluationRequestData.client_id = null;
      this.evaluationRequestData.contract_id = null;
      this.evaluationRequestData.quotation_id = null;
      this.filteredClients = this.filterClientList(type);
    },
    filterClientList(type) {
      return this.clients.filter((client) => {
        if (type === 'contract') {
          return client.type !== 'individuals';
        }
        return client.type === 'individuals';
      });
    },
    quotation_idChanged(quotation_id) {
      this.evaluationRequestData.quotation_id = quotation_id;
    },
    contract_idChanged(contract_id) {
      this.evaluationRequestData.contract_id = contract_id;
    },
    clientChanged(client_id) {
      this.evaluationRequestData.client_id = client_id;
      this.evaluationRequestData.contract_id = null;
      this.evaluationRequestData.quotation_id = null;

      this.contracts = this.filteredClients
        .find((client) => client.id === client_id)
        .contracts.map((c) => ({
          label: c.reference,
          value: c.id,
          ...c,
        }));
      this.quotations = this.filteredClients
        .find((client) => client.id === client_id)
        .quotations.map((c) => ({
          label: c.reference,
          value: c.id,
          ...c,
        }));
    },
    statusChanged(status) {
      this.evaluationRequestData.status_id = status;
    },
    addEvaluationRequest() {
      this.$refs.refFormObserver
        .validate()
        .then(async (success) => {
          if (success) {
            this.$store
              .dispatch(
                'evaluationRequest/addEvaluationRequest',
                this.evaluationRequestData,
              )
              .then((response) => {
                this.$toast.success(response.data.message);
                router.push('/evaluation-request').catch(() => {});
              })
              .catch((err) => {
                if (err.response) {
                  if (err.response.status === 422) {
                    this.$toast.error(err.response.data.message);
                    const { message, errors } = err.response.data;
                    if (errors) {
                      const errorMessage = {
                        message: '',
                        type: 'danger',
                      };
                      Object.values(errors).forEach((error) => {
                        errorMessage.message += `${error[0]}<br>`;
                      });
                      this.displayMessage(errorMessage);
                    }
                    this.$toast({
                      component: ToastificationContent,
                      props: {
                        title: message,
                        text: message,
                        icon: 'AlertCircleIcon',
                        variant: 'danger',
                      },
                    });
                  } else {
                    this.$toast.error(err.response.data.error);
                  }
                }
                console.log(err);
              });
          }
        })
        .catch((err) => {
          if (err.response) {
            if (err.response.status === 422) {
              this.$toast.error(err.response.data.message);
              this.$refs.refFormObserver.setErrors(err.response.data.errors);
            } else {
              this.$toast.error(err.response.data.error);
            }
          }
          console.log(err);
        });
    },
  },
  setup(props) {
    const EVALUATIONREQUEST_STORE_MODULE_NAME = 'evaluationRequest';

    // Register evaluationRequest
    if (!store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) {
      store.registerModule(
        EVALUATIONREQUEST_STORE_MODULE_NAME,
        evaluationRequestStore,
      );
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EVALUATIONREQUEST_STORE_MODULE_NAME)) store.unregisterModule(EVALUATIONREQUEST_STORE_MODULE_NAME);
    });
    const blankEvaluationRequestData = {
      client_id: null,
      contract_id: null,
      quotation_id: null,
      property: {
        property_type_id: null,
        property_classification_id: null,
      },
    };
    const filteredTypes = ref([]);

    const typeOptions = [
      {
        text: 'عقود',
        value: 'contract',
      },
      {
        text: 'عملاء',
        value: 'quotation',
      },
    ];

    const evaluationRequestData = ref(
      JSON.parse(JSON.stringify(blankEvaluationRequestData)),
    );
    const resetevaluationRequestData = () => {
      evaluationRequestData.value = JSON.parse(
        JSON.stringify(blankEvaluationRequestData),
      );
    };

    const clients = ref([]);
    const filteredClients = ref([]);
    const contracts = ref([]);
    const types = ref([]);
    const classifications = ref([]);
    const cities = ref([]);
    store.dispatch('evaluationRequest/fetchCities').then((response) => {
      const { data } = response.data;
      cities.value = data.map((c) => ({
        label: c.name_ar,
        value: c.id,
        ...c,
      }));
    });
    const res = store
      .dispatch('evaluationRequest/fetchClients')
      .then((response) => {
        const { data } = response.data;

        clients.value = data.map((c) => ({
          label: c.name,
          value: c.id,
          ...c,
        }));
        filteredClients.value = clients.value

        store
          .dispatch('evaluationRequest/fetchContractOptions')
          .then((response) => {
            const { propertyTypes, propertyClassifications } = response.data;
            types.value = propertyTypes.map((c) => ({
              label: c.name,
              value: c.id,
              ...c,
            }));
            filteredTypes.value = types.value;

            classifications.value = propertyClassifications.map((c) => ({
              label: c.name,
              value: c.id,
              ...c,
            }));
          });

        if (props.contract) {
          evaluationRequestData.value.type = 'contract';
          filteredClients.value = clients.value.filter(
            (c) => c.type !== 'individuals',
          );
          evaluationRequestData.value.client_id = props.contract.client_id;
          contracts.value = filteredClients.value
            .find((client) => client.id === props.contract.client_id)
            .contracts.map((c) => ({
              label: c.reference,
              value: c.id,
              ...c,
            }));
          evaluationRequestData.value.contract_id = props.contract.id;
        }
        if (props.cost) {
          evaluationRequestData.value.property = {
            property_type_id: props.cost.property_type_id,
            property_classification_id: props.cost.property_classification_id,
          };
        }
      });

    const statuses = [
      {
        label: 'نشط',
        value: 'نشط',
      },
      {
        label: 'ملغى',
        value: 'ملغى',
      },
      {
        label: 'منتهي',
        value: 'منتهي',
      },
    ];

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetevaluationRequestData,
    );

    return {
      cities,
      types,
      filteredTypes,
      classifications,
      filteredClients,
      evaluationRequestData,
      clients,
      contracts,
      typeOptions,
      statuses,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
